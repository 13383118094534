<template>
  <body class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="bg-pattern-style bg-pattern-style-register">
        <div class="content">
          <!-- Register Content -->
          <div class="account-content">
            <div class="account-box">
              <div class="login-right">
                <img
                  :src="require('@/assets/img/logos/logo.png')"
                  class="img-fluid auth-img"
                  alt="Logo"
                />
                <div class="login-header">
                  <h3>
                    <span
                      ><router-link :to="{ name: 'Home' }"
                        >Eparchemin</router-link
                      ></span
                    >
                    Register
                  </h3>
                </div>
                <!-- Register Form -->
                <form method="post">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group has-error">
                        <label class="form-control-label">{{
                          $t("RegisterPage.FIRST_NAME")
                        }}</label>
                        <input
                          id="first-name"
                          type="text"
                          v-model="firstName"
                          :class="getFirstNameClass"
                          class="form-control"
                          autofocus
                        />
                        <span
                          class="invalid-feedback"
                          v-if="getFirstNameClass == 'is-invalid'"
                          >{{
                            $t(
                              "RegisterPage.THE_FIRST_NAME_MUST_HAVE_MUST_AT_LEAST_3_CHARACTERS"
                            )
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label">{{
                          $t("RegisterPage.LAST_NAME")
                        }}</label>
                        <input
                          id="last-name "
                          v-model="lastName"
                          type="text"
                          class="form-control"
                          :class="getlastNameClass"
                          name="last_name"
                        />
                        <span
                          class="invalid-feedback"
                          v-if="getlastNameClass == 'is-invalid'"
                          >{{
                            $t(
                              "RegisterPage.THE_LAST_NAME_MUST_HAVE_MUST_AT_LEAST_3_CHARACTERS"
                            )
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-control-label">{{
                      $t("RegisterPage.EMAIL_ADDRESS")
                    }}</label>
                    <input
                      id="email"
                      v-model="email"
                      type="email"
                      @focus="MoveMessageExistUser"
                      @blur="VerifExistMail"
                      class="form-control "
                      :class="emailIsUsed ? 'is-invalid' : getEmailClass"
                    />
                    <span
                      class="invalid-feedback"
                      v-if="getEmailClass == 'is-invalid'"
                      >{{ $t("RegisterPage.THE_EMAIL_IS_INVALID") }}</span
                    >
                    <span class="invalid-feedback" v-if="emailIsUsed">{{
                      $t("RegisterPage.ADRESS_MAIL_EXISTANT")
                    }}</span>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label">{{
                          $t("RegisterPage.PASSWORD")
                        }}</label>
                        <input
                          id="password"
                          v-model="password"
                          type="password"
                          class="form-control"
                          :class="getPassClass"
                          name="password"
                        />
                        <span
                          class="invalid-feedback"
                          v-if="getPassClass == 'is-invalid'"
                          >{{
                            $t(
                              "RegisterPage.PASSWORD_MUST_CONTAIN_AT_LEAST_3_CHARACTERS"
                            )
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label">{{
                          $t("RegisterPage.CORFIRM_PASSWORD")
                        }}</label>
                        <input
                          id="password-confirm"
                          v-model="Cpassword"
                          type="password"
                          class="form-control"
                          :class="getCPassClass"
                          name="password_confirmation"
                        />
                        <span
                          class="invalid-feedback"
                          v-if="getCPassClass == 'is-invalid'"
                          >{{
                            $t("RegisterPage.MOT_DE_PASSES_DIFFERENTS")
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- <div class="form-group">
										<div class="custom-control custom-control-xs custom-checkbox">
											<input type="checkbox" class="custom-control-input" name="agreeCheckboxUser" id="agree_checkbox_user">
											<label class="custom-control-label" @click="ShowBtn" for="agree_checkbox_user">I agree to Mentoring</label> <a tabindex="-1" href="javascript:void(0);">Privacy Policy</a> &amp; <a tabindex="-1" href="javascript:void(0);"> Terms.</a>
										</div>
									</div> -->
                  <button
                    class="btn btn-primary login-btn"
                    id="btn"
                    @click.prevent="SoumettreInfo"
                    disabled
                  >
                    {{ $t("RegisterPage.CREATE_ACCOUNT") }}
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="isSending"
                    ></span>
                  </button>
                  <div class="account-footer text-center mt-3">
                    {{ $t("RegisterPage.ALREADY_HAVE_AN_ACCOUNT") }}
                    <router-link to="/Login" class="forgot-link mb-0"
                      >Login</router-link
                    >
                  </div>
                </form>
                <!-- /Register Form -->
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </body>
</template>

<script>
export default {
  nama: "Register",
  data() {
    return {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      Cpassword: "",
      emailIsUsed: false,
      isSending: false,
    };
  },
  computed: {
    getFirstNameClass() {
      if (this.firstName.length == 0) return "";
      if (this.firstName.length < 3) {
        this.AllInfoIncorrect;
        return "is-invalid";
      } else {
        this.AllInfoIncorrect;
        return "is-valid";
      }
    },
    getlastNameClass() {
      if (this.lastName.length == 0) return "";
      if (this.lastName.length < 3) {
        this.AllInfoIncorrect;
        return "is-invalid";
      } else {
        this.AllInfoIncorrect;
        return "is-valid";
      }
    },
    getPassClass() {
      if (this.password.length == 0) return "";
      if (this.password.length < 3) {
        this.AllInfoIncorrect;
        return "is-invalid";
      } else {
        this.AllInfoIncorrect;
        return "is-valid";
      }
    },
    getCPassClass() {
      if (this.Cpassword.length == 0) return "";
      if (this.password != this.Cpassword) return "is-invalid";
      return "is-valid";
    },
    validEmail() {
      return this.$store.state.emailRegex.test(this.email);
    },
    getEmailClass() {
      if (this.email.length == 0) return "";
      if (this.email.length > 0 && !this.validEmail) {
        this.AllInfoIncorrect;
        return "is-invalid";
      } else {
        if (this.emailIsUsed == true) {
          return "";
        } else {
          this.AllInfoCorrect;
          return "is-valid";
        }
      }
    },
    AllInfoCorrect() {
      if (
        this.email.length > 0 &&
        this.validEmail &&
        this.lastName.length >= 3 &&
        this.firstName.length >= 3 &&
        this.password.length >= 3 &&
        this.password == this.Cpassword
      ) {
        document.getElementById("btn").disabled = false;
      }
      return true;
    },
    AllInfoIncorrect() {
      if (
        (this.email.length > 0 && !this.validEmail) ||
        this.lastName.length < 3 ||
        this.firstName.length < 3 ||
        this.password.length < 3 ||
        this.password != this.Cpassword
      ) {
        document.getElementById("btn").disabled = true;
      }
      return true;
    },
  },
  methods: {
    VerifExistMail() {
      if (this.getEmailClass == "is-valid") {
        this.$http
          .get("/auth/user/check-email/" + this.email + "/")
          .then((res) => {
            if (res != undefined) this.emailIsUsed = res.data.success;
            else this.emailIsUsed = false;
          }),
          (error) => {
            console.log(error);
          };
      }
    },
    MoveMessageExistUser() {
      this.emailIsUsed = false;
    },
    async SoumettreInfo() {
      this.isSending = true;
      if (this.emailIsUsed == false) {
        const data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
        };
        await this.$http
          .post("/auth/register/", data)
          .then(() => {
            this.firstName = "";
            this.lastName = "";
            this.email = "";
            this.password = "";
            this.Cpassword = "";
            this.isSending = false;
            this.$toast.success("SUcess! You can login now!");
            this.$router.push({ name: "Login" });
          })
          .catch(() => {
            this.isSending = false;
            this.$toast.error("An error occured! Please try egain!");
          });
      }
    },
  },
};
</script>
